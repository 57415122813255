<template>
  <div class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content">
      <form @submit.prevent="saveContact" autocomplete="off" class="lk">
        <div class="lk__inputs">
          <div class="lk__field">
            <label>Ответственный:</label>
            <v-select appendToBody :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" :value="{ label: fields.responsible_user_name, value: fields.responsible_user_id }" @input="onUserChange" placeholder="" v-if="!disabled">
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div class="lk__field">
            <label for="name">ФИО:</label>
            <input id="name" type="text" v-model="fields.name">
          </div>
          <div class="lk__field">
            <label>Теги:</label>
            <TagsInput @input="onTagsInput"/>
          </div>
          <div class="lk__field">
            <label for="email">E-mail:</label>
            <input id="email" type="email" v-model="fields.email">
          </div>
          <div class="lk__field">
            <label for="city">Город:</label>
            <input id="city" type="text" v-model="fields.city">
          </div>
          <div class="lk__field">
            <label for="post">Должность:</label>
            <input id="post" type="text" v-model="fields.post" required>
          </div>
          <div class="lk__field">
            <label>Телефоны:</label>
            <div class="phones">
              <div :key="index" class="phones__item" v-for="(phone, index) in fields.phones">
                <div class="phones__phone">
                  <input type="text" v-model="phone.value">
                </div>
                <div class="small-buttons">
                  <button @click="removePhone($event, index)" class="btn btn--small" type="button" v-if="index !== 0">
                    <MinusIco/>
                  </button>
                  <button @click="addPhone" class="btn btn--small" type="button">
                    <PlusIco/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button class="btn" type="submit">ДОБАВИТЬ</button>
        </div>
        <div class="lk__back">
          <ContactsBack/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DownIcon from '@/components/svg/DownIcon'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import PlusIco from '@/components/svg/plus'
import MinusIco from '@/components/svg/minus'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import ADD_CONTACT from '@/graphql/mutations/AddContact.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import ContactsBack from '@/components/svg/ContactsBack'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'AddCompany',
  components: {
    ContactsBack,
    MinusIco,
    PlusIco,
    TagsInput,
    DownIcon
  },
  data () {
    return {
      title: 'Добавить контакт',
      disabled: true,
      fields: {
        name: '',
        email: '',
        city: '',
        post: '',
        responsible_user_id: '',
        responsible_user_name: '',
        group_id: '',
        company_id: null,
        phones: [
          {
            value: ''
          }],
        tags: []
      }
    }
  },
  created () {
    this.fields.responsible_user_id = this.$store.state.me._id
    this.fields.responsible_user_name = this.$store.state.me.name
    this.disabled = this.$store.state.me.role === 'manager'
  },
  methods: {
    getCurrentPhone () {
      let currentPhones = []

      this.fields.phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone.value)
        }
      })

      return currentPhones
    },
    saveContact () {
      let currentInput = {
        name: this.fields.name,
        email: this.fields.email,
        city: this.fields.city,
        post: this.fields.post,
        group_id: this.fields.group_id,
        responsible_user_id: this.fields.responsible_user_id,
        phone: this.getCurrentPhone(),
        tags: this.fields.tags
      }

      this.$apollo.mutate({
        mutation: ADD_CONTACT,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data && data.addContact && data.addContact._id) {
            this.$router.push({ name: 'contacts' })
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    addPhone () {
      this.fields.phones.push({ value: '' })
    },
    removePhone (e, index) {
      e.preventDefault()

      this.fields.phones.splice(index, 1)
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    },
    onTagsInput (tags) {
      this.fields.tags = tags.map(item => item.value)
    },
    onUserChange (e) {
      if (e) {
        this.fields.responsible_user_id = e.value
        this.fields.responsible_user_name = e.label
      } else {
        this.fields.responsible_user_id = null
        this.fields.responsible_user_name = null
      }
    }
  }
}
</script>
